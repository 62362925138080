import React from 'react'
import MrDmarcyLayout from '../../../components/Layouts/MrDmarcyLayout'

const IndexPage: React.FunctionComponent = () => {
  return (
    <MrDmarcyLayout>
      <h2 className="mb-3 text-secondary">Email authentication is no walk in Mansfield park</h2>
      <p>
        A proper SPF, DKIM &amp; DMARC set up is vital for email infrastructure - but it can also be confusing. Mr Dmarcy is your partner in:
      </p>
      <ul>
        <li>turning hundreds of reports into easily digestible charts</li>
        <li>stopping your emails going to junk and mail delivery system failures</li>
        <li>monitoring trends and sending alerts</li>
      </ul>
      <p>
        Mr Dmarcy will help you set up your email infrastructure and monitor it so you can focus on more exciting things. If that sounds handsome enough to tempt you, we’re on the hunt for beta users.
      </p>
    </MrDmarcyLayout>
  )
}

export default IndexPage
