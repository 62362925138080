import React from 'react'
import Navbar from './Navbar'
import SEO from '../../SEO'
import Layout from '../Layout'
import { VerticalSpace } from '../../VerticalSpace'

interface MrDmarcyLayoutProps {

}

const description = "SPF, DKIM and DMARC can be time consuming to implement and expensive and difficult to understand and analyze; but it shouldn't be. Creative Ilk's DMARC analyzer makes email infrastructure easy. Set your email infrastructure up now."

const MrDmarcyLayout: React.FunctionComponent<MrDmarcyLayoutProps> = ({ children }) => {
  return (
    <React.Fragment>
      {/* <Navbar /> */}
      <SEO title="Email analytics" description={description} />
      <Layout title="DMARC Analytics" >
        <VerticalSpace height={50} />
        <div className="pt-15 container text-left">
          <div className="container">
            <h2 className="mb-3 text-secondary">Email infrastructure made easy</h2>
            <p>
              It is a truth universally acknowledged, that anyone in possession of an ESP, must be in want of a better way to validate DMARC, DKIM, and SPF.
          </p>
            <a href="//dmarc.creativeilk.com" className="btn btn-primary my-3">
              Sign Up
          </a>
          </div>
        </div>
        <div className="container py-5">
          {children}
        </div>
      </ Layout >

    </React.Fragment>
  )
}

export default MrDmarcyLayout
